import React from "react"
import { gsap } from "gsap"

import "../scss/styles.scss"

import Layout from "../components/layout"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"

import Intro from "./intro"
import Porphyrie from "./porphyrie"
import Leitlinien from "./leitlinien"
import About from "./about"
import Kompetenzzentren from "./kompetenzzentren"
import Download from "./download"
import Kontakt from "./kontakt"

const IndexPage = () => (
  <>
    <main>
      <Header />

      <Layout>
        <Intro />

        <Porphyrie />

        <Leitlinien />

        <About />

        <Kompetenzzentren />

        <Download />

        <Kontakt />
      </Layout>

      <Footer />
    </main>
  </>
)

export default IndexPage
