//import * as React from "react"
import React, { createRef, useRef } from "react"
import { FaFileDownload } from "react-icons/fa"

const downloads = [
  {
    id: "0",
    headline: "aerzteblatt.de",
    text: "Wesentliche Beiträge zu Stoffwechselstörungen",
    year: "2015",
    type: "PDF",
    size: "309 KB",
    path: "downloads/Aerzteblatt-MOD.pdf",
  },
  {
    id: "1",
    headline: "Akute hepatische Porphyrie",
    text: "Eine Krankheit der Könige",
    year: "xxxx",
    type: "PDF",
    size: "55 KB",
    path: "downloads/Akute_hepatische_Porphyrien.pdf",
  },
  {
    id: "2",
    headline: "Akute hepatische Porphyrie",
    text: "Klinik, Diagnose und Therapie",
    year: "xxxx",
    type: "PDF",
    size: "42 KB",
    path: "downloads/Akute_hepatische_Porphyrie-eine_Krankheit_der_Koenige.pdf",
  },
  {
    id: "3",
    headline: "Hepatische Porphyrien",
    text: "Kurzfassung eines Vortrags auf dem 3. Hepatologisschen Colloquium",
    year: "xxxx",
    type: "PDF",
    size: "97 KB",
    path: "downloads/Hepatische-Porphyrien.pdf",
  },
  {
    id: "4",
    headline: "Labor Aktuell",
    text: "Basisdiagnostik der Porphyrien",
    year: "xxxx",
    type: "PDF",
    size: "4,1 MB",
    path: "downloads/LaborAktuell_Porphyriediagnostik.pdf",
  },
  {
    id: "5",
    headline: "Nomenklatur für hereditäre Porphyrien",
    year: "xxxx",
    type: "PDF",
    size: "94 KB",
    path: "downloads/Nomenklatur-fuer-hereditaere-Porphyrien.pdf",
  },
  {
    id: "6",
    headline: "Porphyriediagnostik Labor Volkmann",
    year: "xxxx",
    type: "PDF",
    size: "144 KB",
    path: "downloads/Porphyriediagnostik_Labor_Volkmann.pdf",
  },
  {
    id: "7",
    headline: "Porphyriediagnostik",
    text: "Informationen zur Porphyriediagnostik",
    year: "xxxx",
    type: "PDF",
    size: "162 KB",
    path: "downloads/Porphyriediagnostik.pdf",
  },
  {
    id: "8",
    headline: "Porphyrien und sekundäre Porphyrinopathien",
    year: "xxxx",
    type: "PDF",
    size: "47 KB",
    path: "downloads/Porphyrien_und_sekundaere_Porphyrinopathien.pdf",
  },
  {
    id: "9",
    headline: "Presse",
    text: "Blei hemmt lebensnotwendiges Enzym",
    year: "1988",
    type: "PDF",
    size: "403 KB",
    path: "downloads/Presse.pdf",
  },
  {
    id: "10",
    headline: "Synopsis",
    text: "Krankheiten und Störungen der Porphyrin- und Hämbiosynthese",
    year: "xxxx",
    type: "PDF",
    size: "182 KB",
    path: "downloads/Synopsis.pdf",
  },
  {
    id: "11",
    headline: "Literatur",
    text: "Literaturvorschläge kuratiert von Prof. Dr. Doss",
    year: "xxxx",
    type: "PDF",
    size: "54 KB",
    path: "downloads/Literatur.pdf",
  },
]

const DownloadPage = () => {
  const downloadElem = useRef([])

  downloadElem.current = downloads.map(({ path }, i) => {
    downloadElem.current[i] ?? createRef()
  })

  const downloadFile = file => {
    window.open(file, "_blank")
  }

  return (
    <section id="download" className="hero is-light" data-scroll-section>
      <div className="hero-body">
        <div className="container is-max-widescreen">
          <h2 className="title">Downloads</h2>
          {/* <h3 className="subtitle">Subline</h3> */}

          <div className="tile is-ancestor is-multiline mt-5">
            {downloads.map((downloads, i) => (
              <div
                className="tile is-parent is-3"
                key={i}
                ref={downloadElem.current[i]}
                onClick={() => downloadFile(downloads.path)}
              >
                <article className="tile is-child box">
                  <p className="title is-6">
                    <a
                      ref={downloadElem}
                      download={downloads.headline}
                      href={downloads.path}
                    >
                      {downloads.headline}
                    </a>
                    <FaFileDownload
                      className="download-icon"
                      alt={"Download document"}
                    />
                  </p>
                  <p className="subtitle is-7">{downloads.text}</p>
                  <div className="tags mt-auto">
                    <span className="tag is-info is-light ml-auto">
                      {downloads.size}
                    </span>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default DownloadPage
