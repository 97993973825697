import * as React from "react"

const IntroPage = () => (
  <>
    {/* <section className="hero is-small is-light" data-scroll-section> */}
    <section
      id="intro"
      /* className="hero is-fullheight is-light intro-gradient next-section-gradient-to-white" */
      className="hero is-fullheight is-light intro-gradient"
      data-scroll-section
    >
      <div className="hero-body">
        <div className="container is-max-widescreen">
          <div className="content">
            <h2 className="title is-2 mb-6">
              Wir heißen Sie auf der Seite der Porphyriediagnostik willkommen.
            </h2>
            <h3 className="title is-3 mr-6">
              Hier finden Sie Information zu verschiedenen Porphyrien,
              Konsultationen und Diagnostiken sowie weiterführenden
              Ansprechpartnern. <br />
              <br />
              Im Download Bereich finden Sie zusätzliche Informationen zu
              Diagnostik und Literatur.
            </h3>
            {/* <div className="columns">
              <div className="column is-half mx-auto">Intro Text goes here</div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  </>
)

export default IntroPage
