import * as React from "react"

const leitlinien = [
  {
    id: "0",
    text: "Porphyrien sind hereditäre Stoffwechselkrankheiten und werden nach ihrer klinischen Symptomatik in akute und nicht-akute Porphyrien differenziert. Akute Porphyrien entwickeln ein abdominal-kardiovaskuläres-neurologisches Syndrom, welches potentiell lebensbedrohlich ist. Arzneistoffe, Sexualhormone und Nahrungskarenz sind die wichtigsten Manifestationsfaktoren akuter Porphyrien. Kutane Symptome charakterisieren die nicht-akuten Porphyrien.",
  },
  {
    id: "1",
    text: "Porphyrie ist eine pathobiochemische Diagnose. Der klinische Verdacht auf eine Porphyrie muß durch Metabolitenuntersuchungen im Urin, Stuhl und Blut gesichert werden. Die Leitparamter zur Diagnose einer akuten Porphyrie sind die exzessiv erhöhten Porphyinvorläufer Delta-Aminolävulinsäure und Porphobilinogen sowie Porphyrine im Urin. Die Differentialdiagnose der verschiedenen Porphyrieformen wird in einem zweiten Schritt in Urin-, Stuhl- und Blutproben durchgeführt. Im Gegensatz zu den akuten Porphyrien sind bei den nicht-akuten Porphyrien die bei den Porphyrinvorläufer nicht erhöht. Enzymbestimmungen und molekulargenetische Untersuchungen sind für die klinische Diagnostik und Therapie nicht relevant.",
  },
  {
    id: "2",
    text: "Die beiden diagnostischen Fallgruben sind die Verwechselung von akuten und nicht-akuten Porphyrien und die Fehlinterpretation einer sekundären Porphyrinurie als hereditäre Porphyrie.",
  },
  {
    id: "3",
    text: "Die Therapie ist für sämtliche akuten Porphyrien gleichartig. Grundlegend ist die regulatorische Therapie mit Hämarginat sowie mit oraler und intravenöser Glukosegabe. Porphyrinogene Faktoren müssen erkannt und vermieden werden.",
  },
  {
    id: "4",
    text: "Nicht-akute Porphyrien: Die Porphyria cutanea tarda wird mit Aderlaß und niedrig- dosierten Chloroquin behandelt. Die Therapie der Protoporphyrie erfolgt mit Betacaroten und bei Leberbeteiligung mit Ursodeoxycholsäure. Bei protoporphyrie-assoziierter cholestatischer Zirrhose ist eine Lebertransplantation indiziert.",
  },
  {
    id: "5",
    text: "Für die Prognose sämtlicher Porphyrien sind Patientenberatung und prophylaxe entscheidend.",
  },
]

const PosphyriePage = () => (
  <>
    <section
      id="leitlinien"
      className="section"
      /* className="section next-section-gradient-to-grey" */
      data-scroll-section
    >
      <div className="container is-max-widescreen">
        <h2 className="title">Leitlinien zur Porphyrie</h2>
        <div className="columns">
          <div className="column">
            <div className="content mb-6">
              <ol>
                {leitlinien.map(leitlinien => (
                  <li key={leitlinien.id}>{leitlinien.text}</li>
                ))}
              </ol>
            </div>

            <div className="content mb-6">
              <h3 className="title is-5">
                Hereditäre Enzymdefekte der Hämbiosynthese als Determinanten der
                Porphyrien
              </h3>
              <div className="table-container">
                <table className="table is-fullwidth is-striped has-text-centered">
                  <thead>
                    <tr>
                      <th>Enzyme</th>
                      <th>Metabolite</th>
                      <th>Porphyrien</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>Glycin + Succinyl-CoA</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>ð-Aminolävulinsäure-Synthase</td>
                      <td>&darr;</td>
                      <td>Sideroblatische Anämie</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>ð-Aminolävulinsäure</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        ð-Aminolävulinsäure-Dehydratase<sup>*</sup>
                      </td>
                      <td>&darr;</td>
                      <td>ALS-Dehydratasedefekt-Porphyrie (Doss-Porphyrie)</td>
                    </tr>
                  </tbody>
                </table>
                <div className="table-footnotes">
                  <p className="is-size-7 mb-3">
                    <sup>*</sup>Synonym: Porphobilinogen-Synthese
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default PosphyriePage
