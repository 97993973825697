import PropTypes from "prop-types"
import React from "react"
import Navbar from "../navbar/navbar"

const Header = ({ siteTitle }) => (
  <>
    <Navbar siteTitle={siteTitle || `Title`} />
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
