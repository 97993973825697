import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { gsap } from "gsap/all"
import PropTypes from "prop-types"
import logo from "../../images/pd_logo.svg"

const isActive = ({ isCurrent }) => {
  return isCurrent
    ? { className: "nav-link active" }
    : { className: "nav-link" }
}

const ExactNavLink = props => <Link getProps={isActive} {...props} />

const Navbar = ({ siteTitle }) => {
  const [toggleNav, setToggleNav] = useState(false)
  const showNav = () => setToggleNav(!toggleNav)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const navElem = useRef()
  //const navElem = gsap.utils.selector(el)
  //const navTarget = e.target.getAttribute("data-id")

  useEffect(() => {
    //gsap.to(navElem.current, { rotation: "+=360" })
    //console.log(navElem)
  })

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container is-max-widescreen">
        <div className="navbar-brand">
          <a
            role="button"
            className="navbar-burger ml-0"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={showNav}
            tabIndex={0}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>

          {/* <Link to="/" className="navbar-item" href="#">
            <StaticImage
              src="../../images/pd_logo.svg"
              height={100}
              quality={95}
              loading="eager"
              placeholder="none"
              alt="PD"
            />
          </Link> */}

          <Link to="/" className="navbar-item" href="#">
            <img src={logo} height="100px" alt="Logo" />
          </Link>
        </div>

        <div
          id="navbarMenu"
          className={`${toggleNav ? "navbar-menu is-active" : "navbar-menu"}`}
        >
          <div className="navbar-end">
            <a
              className="navbar-item"
              data-nav-target="porphyrie"
              href="#porphyrie"
            >
              Porphyrie
            </a>
            <a
              className="navbar-item"
              data-nav-target="leitlinien"
              href="#leitlinien"
            >
              Leitlinien
            </a>
            <a
              className="navbar-item"
              ref={navElem}
              data-nav-target="about"
              href="#about"
            >
              Manfred Doss
            </a>
            <a
              className="navbar-item"
              ref={navElem}
              data-nav-target="kompetenzzentren"
              href="#kompetenzzentren"
            >
              Kompetenzzentren
            </a>
            <a
              className="navbar-item"
              data-nav-target="download"
              href="#download"
            >
              Downloads
            </a>
            <a
              className="navbar-item"
              data-nav-target="kontakt"
              href="#kontakt"
            >
              Kontakt
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
