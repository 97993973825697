import * as React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

const KompetenzzentrenPage = () => (
  <>
    <section
      id="kompetenzzentren"
      className="hero is-light next-section-gradient-to-white"
      data-scroll-section
    >
      <div className="hero-body">
        <div className="container is-max-widescreen">
          <h2 className="title has-text-centered">
            Deutsches Kompetenz-Zentrum für <br />
            Porphyriediagnostik und Konsultation
          </h2>

          <div className="tile is-ancestor mt-6">
            <div className="tile is-parent is-5 ml-auto mr-3">
              <article className="tile is-child box">
                <p className="title is-4">
                  Prof. Dr. med. habil. Ulrich Stölzel
                </p>
                <p className="subtitle is-6">
                  Leiter Porphyriezentrum
                  <br />
                  Klinikum Chemnitz, Zentrum für Innere Medizin II
                </p>
                <p>
                  Flemmingstraße 2<br />
                  09116 Chemnitz
                  <br />
                  <br />
                  Tel.: +49 172 / 3708552
                  <br />
                  Fax.: +49 37133333224
                  <br />
                  <br />
                  E-Mail:&nbsp;
                  <a href="mailto:dr.stoelzel@porphyrie.de">
                    dr.stoelzel@porphyrie.de
                  </a>
                  <br />
                  <a
                    href="https://www.klinikumchemnitz.de/kliniken-bereiche/behandlungszentren/porphyriezentrum/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaExternalLinkAlt size={".75em"} alt={"External Link"} />
                    &nbsp;Web
                  </a>
                </p>
              </article>
            </div>

            <div className="tile is-parent is-5 mr-auto ml-3">
              <article className="tile is-child box">
                <p className="title is-4">
                  Dr. rer. nat. Thomas Stauch
                  <br />
                  Prof. Dr. med. Manfred Otto Doss
                </p>
                <p className="subtitle is-6">
                  im MVZ Labor PD Dr. Volkmann und Kollegen GbR Speziallabor für
                  Porphyriediagnostik
                </p>
                <p>
                  Gerwigstr. 67
                  <br />
                  76131 Karlsruhe
                  <br />
                  <br />
                  Tel.: 0721 / 850 001 65
                  <br />
                  Fax.: 0721 / 850 001 15
                  <br />
                  <br />
                  E-Mail:&nbsp;
                  <a href="t.stauch@porphyrie.de">t.stauch@porphyrie.de</a>
                  <br />
                  <a
                    className="external"
                    href="https://laborvolkmann.de/analysenspektrum/HTML/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaExternalLinkAlt size={".75em"} alt={"External Link"} />
                    &nbsp;Web
                  </a>
                  <br />
                  <br />
                  Bitte beachten Sie das Formular „Porphyriediagnostik“ des MVZ
                  Labors Volkmann im Bereich <a href="#download">„Downloads“</a>
                  .
                </p>
              </article>
            </div>
          </div>

          <div className="tile is-ancestor mt-6">
            <div className="tile is-parent is-5 mx-auto">
              <article className="tile is-child box">
                <p className="title is-4">
                  Porphyrie-Ambulanz in Marburg
                  <br />
                  Dr. med. Margareta Doss-Frank
                </p>
                <p className="subtitle is-6">
                  Internistin-Gastroenterologin Internistische
                  Gemeinschaftspraxis
                </p>
                <p>
                  Biegenstraße 3<br />
                  35037 Marburg
                  <br />
                  <br />
                  Tel.: 06421/917570
                  <br />
                  Fax.: 06421/9175720
                  <br />
                  <br />
                  E-Mail:&nbsp;
                  <a className="external" href="mailto: m.frank@porhyrie.de">
                    m.frank@porhyrie.de
                  </a>
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default KompetenzzentrenPage
